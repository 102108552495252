var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-app", { attrs: { "fill-height": "" } }, [
    _c("div", { staticClass: "row flex-column h-100" }, [
      _c(
        "div",
        { staticClass: "d-flex flex-column align-items-center mt-5 mb-10" },
        [
          _c("div", { staticClass: "d-flex align-items-center" }, [
            _c(
              "span",
              {
                staticClass: "f13",
                class: !this.$vuetify.rtl ? "visible" : "invisible",
              },
              [_vm._v(_vm._s(_vm.$t("RIAL")))]
            ),
            _c("span", { staticClass: "mx-2 f24 font-weight-bold" }, [
              _vm._v(
                _vm._s(
                  _vm._f("VMask")(_vm.accountBalance.cash, _vm.currencyMask)
                )
              ),
            ]),
            _c(
              "span",
              {
                staticClass: "f13",
                class: this.$vuetify.rtl ? "visible" : "invisible",
              },
              [_vm._v(_vm._s(_vm.$t("RIAL")))]
            ),
          ]),
          _c("span", { staticClass: "f14 mt-4" }, [
            _vm._v(" " + _vm._s(_vm.$t("Your balance")) + " "),
          ]),
          _c(
            "v-btn",
            {
              staticClass: "mt-4 rounded-xl",
              attrs: { color: "primary", large: "" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.settlementRequest.apply(null, arguments)
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("Request for settlement")) + " ")]
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass:
            "bg-white d-flex flex-column grow rounded-t-xl text-dark p-4",
        },
        [
          _c("span", { staticClass: "f15 fw-bold" }, [
            _vm._v(_vm._s(_vm.$t("Transactions"))),
          ]),
          _vm.accountBalance.details && _vm.accountBalance.details.length > 0
            ? _c(
                "div",
                _vm._l(_vm.accountBalance.details, function (item, index) {
                  return _c(
                    "v-card",
                    {
                      key: index,
                      staticClass: "my-2 border",
                      attrs: { flat: "", hover: "", ripple: "", light: "" },
                    },
                    [
                      item.status == "DEPOSIT" && item.isSettled == true
                        ? _c("div", { staticClass: "badge-promo mb-1" }, [
                            _c(
                              "span",
                              { staticClass: "badge-promo-content f11" },
                              [_vm._v(" " + _vm._s(_vm.$t("Settled")) + " ")]
                            ),
                          ])
                        : _vm._e(),
                      item.status == "DEPOSIT" &&
                      item.isSettled == false &&
                      item.settlementId
                        ? _c("div", { staticClass: "badge-promo mb-1" }, [
                            _c(
                              "span",
                              { staticClass: "badge-promo-content f11" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("Awaiting settlement")) +
                                    " "
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      _c(
                        "b-list-group-item",
                        {
                          staticClass:
                            "d-flex align-items-center rounded py-2 ps-2 pe-3 border-none",
                        },
                        [
                          item.status == "DEPOSIT"
                            ? _c(
                                "v-avatar",
                                {
                                  attrs: {
                                    color: "green lighten-5 p-2",
                                    size: "48",
                                  },
                                },
                                [
                                  _c("iconly", {
                                    staticClass: "green--text",
                                    attrs: {
                                      type: "outline",
                                      name: "arrow-up",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          item.status == "WITHDRAW"
                            ? _c(
                                "v-avatar",
                                {
                                  attrs: {
                                    color: "red lighten-5 p-2",
                                    size: "48",
                                  },
                                },
                                [
                                  _c("iconly", {
                                    staticClass: "red--text",
                                    attrs: {
                                      type: "outline",
                                      name: "arrow-down",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex flex-column flex-fill ms-2 me-0",
                            },
                            [
                              _c("div", { staticClass: "d-flex flex-row" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex flex-row align-items-start justify-content-center",
                                  },
                                  [
                                    item.reason == "CHARGE_WALLET"
                                      ? _c("span", { staticClass: "f13" }, [
                                          _vm._v(
                                            _vm._s(_vm.$t("Wallet charge"))
                                          ),
                                        ])
                                      : _vm._e(),
                                    item.reason == "EXAM" && item.clientFullName
                                      ? _c(
                                          "span",
                                          { staticClass: "f13 ms-0 me-2" },
                                          [_vm._v(_vm._s(item.clientFullName))]
                                        )
                                      : _vm._e(),
                                    item.reason == "EXAM"
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "f11 primary lighten-5 rounded-pill px-2 py-0 primary--text",
                                          },
                                          [_vm._v(_vm._s(_vm.$t("Exam")))]
                                        )
                                      : _vm._e(),
                                    item.reason == "CONSULTANT" &&
                                    item.clientFullName
                                      ? _c(
                                          "span",
                                          { staticClass: "f13 ms-0 me-2" },
                                          [_vm._v(_vm._s(item.clientFullName))]
                                        )
                                      : _vm._e(),
                                    item.reason == "CONSULTANT"
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "f11 primary lighten-5 rounded-pill px-2 py-0 primary--text",
                                          },
                                          [_vm._v(_vm._s(_vm.$t("Consultant")))]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "box d-flex ms-auto me-0 align-items-end justify-content-center",
                                  },
                                  [
                                    item.status == "WITHDRAW"
                                      ? _c(
                                          "span",
                                          { staticClass: "f13 red--text" },
                                          [
                                            _vm._v(
                                              "- " +
                                                _vm._s(
                                                  _vm._f("VMask")(
                                                    item.amount,
                                                    _vm.currencyMask
                                                  )
                                                ) +
                                                " " +
                                                _vm._s(_vm.$t("RIAL"))
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    item.status == "DEPOSIT"
                                      ? _c(
                                          "span",
                                          { staticClass: "f13 green--text" },
                                          [
                                            _vm._v(
                                              "+ " +
                                                _vm._s(
                                                  _vm._f("VMask")(
                                                    item.amount,
                                                    _vm.currencyMask
                                                  )
                                                ) +
                                                " " +
                                                _vm._s(_vm.$t("RIAL"))
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                              ]),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "f11 grey--text d-flex flex-row align-items-center mt-1",
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "far fa-calendar-alt ms-0 me-1",
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("moment")(
                                          item.createDate,
                                          "dddd , jD jMMMM jYYYY"
                                        )
                                      ) +
                                      " "
                                  ),
                                  _c("i", {
                                    staticClass: "far fa-clock ms-2 me-1",
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("moment")(
                                          item.createDate,
                                          "HH:MM"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              )
            : _c("span", { staticClass: "text-center f16 d-block mt-8" }, [
                _vm._v(" " + _vm._s(_vm.$t("No results found")) + " "),
              ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }